import productImage from "./assets/product.png";

export const ERROR_MESSAGES = {
  ENTER_BOTH: "Please enter both email and password",
  VALID_EMAIL: "Please enter a valid email address",
  VALID_PHONE: "Phone number must be exactly 10 digits",
  VALID_PASSWORD: "Please enter a valid password",
  FNAME_ERROR: "Please enter First name",
  LNAME_ERROR: "Please enter Last name",
  EMAILCONFIRM_FAILED: "Failed to confirm email",
  CONFIRMATION_ERROR: "Error during email confirmation",
  EMAILCONFIRM_SUCCESS: "Email confirmed successfully",
  GST_ERR1: "GST Number cannot contain special characters",
  ENTER_ALL: "Please enter all the fields",
  PWD_NOTMATCH: "Passwords do not match",
  PWD_LENGTH: "Password should be minimum of 8 characters",
  CHECK_MAIL: "Please check your mail for the Confirmation Link",
  SUCCESS: "Password changed successfully",
  ERROR: "ERROR",
  PWDRESET_MSG:
    "Enter your Email address below and we will send you Password Reset OTP",
  NAME_ERROR: "Invalid customer name. Only letters and spaces are allowed.",
  NUMBER_ERROR: "Phone Number Should contain only digits",
  PINCODE_ERROR: "Pincode Should contain only numbers",
  AMOUNT_ERROR: "Amount should contain only digits",
  MAIL_ERROR: "Enter a valid Mail",
  GST_ERROR: "Enter GST Number",
  ADD_ERROR: "Enter Address",
  ORDER_ERROR: "Enter Order Details",
  PROD_ERROR: "Enter Product/Services",
  DESC_ERROR: "Enter Description",
  REGISTER_FAILURE: "Failed to register user",
  COMPANY_REQ: "Enter Company Name",
  COSTOMER_REQ: "Enter Customer Name",
  NUMBER_REQ: "Enter Contact Number",
  MAIL_REQ: "Enter Mail ID",
  NAME_REQ: "Enter Name",
  DESC_ERROR1:'Enter Valid description',
  NAME_VALID: "Name should not contain digits",
  CITY_VALID: "Name should not contain digits",
  STATE_VALID: "Name should not contain digits",
  COUNTRY_VALID: "Name should not contain digits",
  NUMBER_LENGTH: "Contact number should be 10 digits",
  GST_LENGTH: "GST Number should be 15 digits",
  AMT_ERR: "Enter amount",
  NUMBER_ONLY: "Number should be only digits",
  NUMBER_10_LENGTH: "Number must be 10 digits",
  INVALID_EMAIL_FORMAT: "Invalid email format",
  GST_NO_IS_REQUIRED: "GST no is required",
  GST_NO_SHOULD: "GST no should contain only alphanumeric characters",
  GST_NO_SHOULD_1: "GST no should be 15 characters",
  BATCH_REQ: "Enter Product",
  BATCH_VAL: "No Special Characters allowed",
  MRP_REQ: "Enter MRP",
  MRP_VAL: "Rate must contain only digits",
  QUANTITY_REQ: "Enter Quantity",
  QUANTITY_VAL: "Quantity must contain only digits",
  AMT_VAL: "Amount must contain only digits",
  AMT_REQ:"Enter Amount",
  HSN_REQ:"Enter HSN Number",
  HSN_ERROR:"HSN Number cannot exceed 8 digits",
  HSN_ERR1:"No special characters allowed",
  HSN_VAL:"HSN Number should contain only digits",
  PROFILE_SUCCESS:"Profile Details updated successfully",
  PROFILE_FAIL:"Profile Details not updated",
  SESSION_VALIDATION:"Your session has expired. Please log in again.",
  RESPONSE_ERROR:"Response not successful: Received status code 403",
  CUSTOMER_REACTIVE:"Customer reactivated successfully.",
  CUSTOMER_DATA:"Error reactivating customer.",
  FIELD_REQ:"This field is required",
  PDT_NAME:"Product Name is required",
  QUAN_REQ:"Quantity is required",
  NUM_ALL:"Only numbers are allowed",
};
 
export const TEXT_MESSAGES = {
  NEW_ORDER1: "New order created with id",
  NEW_QUOTATION1: "New Quotation received with id",
  NEW_PAYMENT1: "Payment completed for invoice id",
  ERR_MENUITEMS: "Error fetching menu items:",
  ENTER_CREDENTIALS: "Enter your credentials to get started",
  WELCOME_MESSAGE: "Hi, Welcome Back",
  ADD_ANOTHER_CONTACT: "Add Another Contact",
  EMAIL_NOTIFY: "Email Notification",
  PRODUCT_TITLE: "My active products",
  PRODUCT_HOME: "Your active products/services",
  PRODUCT_ACTIVE: "2 active services",
  service_title: "Our Services",
  view_all: "View All",
  PRODUCTS_TITLE: "Products",
  PRODUCTS_HOME_TITLE: "Our Products",
  PROFILE_TXT:"Profile",
  LOG_TEXT: "Log In",
  Remove: "Remove",
  SIGN_TEXT: "SIGN UP",
  SIGN_LABEL: "Sign Up",
  LOG_BUTTON: "LOGIN",
  LOGADMIN_BUTTON: "LOGIN AS ADMIN",
  VERIFY_BUTTON: "VERIFY",
  RESET_BUTTON: "RESET",
  SEND_BUTTON: "SEND OTP",
  MODAL_FROM:"From",
  MODAL_TO:'To',
  FORGOT_TEXT: "Forgot Password?",
  RESET_TEXT: "Reset Password",
  Log_SUCCESS: "Login Successful",
  OTP_SENT: "OTP Sent to your Email",
  OTP_VERIFY: "OTP Verified Successfully",
  FORGOT_LABEL: "Forgot Password",
  RESEND_TEXT: "Resend OTP",
  EMAIL_CONFIRM: "Email Confirmation",
  EMAIL_VERIFIED: "Email verified Successfully",
  SORRY: "Sorry, Some Error occured..Please Try again!!",
  EMAIL_REG:/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  SERVICE_PAGE: "Services Page",
  USE_SESSION: "useSession must be used within a SessionProvider",
  USE_SIGNUP: "useSignUpContext must be used within a SignUpContextProvider",
  PRODUCTS: "Products",
  PRODUCT_SITE: "Take me to Product Site",
  SERVICES: "Service 1",
  MOREDETAILS: "More Details",
  PROFILE: "Profile",
  SIGNOUT: "Sign Out",
  REPORTS_TXT: "Reports",
  BASIC_INFO: "Basic Information",
  CONTACT_INFO: " Contact Information",
  BILLING_INFO: "Billing Information",
  MY_PORTAL: "My Portal",
  EDIT: "Edit",
  QUOTATION: "Quotation",
  QUOTE_SHARE_SUCCESS: "shared to Email successfully",
  QUOTE_DRAFT_SUCCESS: "saved as draft successfully",
  QUOTE_DRAFT_FAIL: " not saved as draft",
  QUOTE_SHARE_FAILED: "Error in sharing Quotation to Email",
  INVOICE: "Invoices",
  INVOICE_TXT:"Invoice",
  QUOTATION_ID: "Quotation ID",
  INVOICE_ID:"Invoice ID",
  INVOICE_DATE:"Invoice Date",
  QUOTATION_DATE: "Quotaion Date",
  VALID_TILL: "Valid Till",
  PRODUCT_OR_SERVICE: "Product/Service",
  DESCRIPTION: "Description",
  PRICE: "Price",
  SUBTOTAL: "Subtotal",
  IGST: "IGST 18%",
  TOTAL: "TOTAL",
  ERR_REJECT: "Error rejecting quotation:",
  ERR_ACCEPT: "Error accepting quotation:",
  ACCEPT: "ACCEPT",
  REJECT: "REJECT",
  DEFAULT_ID: "660e717ddeb9b76d7cb75137",
  NEW_CUSTOMER: "+ CREATE CUSTOMER",
  NEW_QUOTATION: "+ CREATE QUOTATION",
  NEW_INVOICE: "+ CREATE INVOICE",
  NEW_ORDER: "+ CREATE ORDER",
  EDIT_iNVOICE: "Edit Invoice",
  EDIT_ORDER: "Edit Order",
  EDIT_Customer: "Edit Customer",
  VIEW_CUSTOMER: "View Customer",
  ADD_CUSTOMER: "Add Customer",
  CREATE_ORDER: "Create Order",
  CUSTOMER: "Customers",
  ORDER: "Orders",
  Home: "Home",
  BUTTON_TXT: "Button Text",
  ANOTHER_CONTACT: "ADD ANOTHER CONTACT",
  CLIENT_PORTAL: "Client Portal",
  REMARKS: "Remarks",
  ADD_PRODUCT:"+ Add Product",
  SAVE: "Save",
  CANCEL: "Cancel",
  CREATE_QUOTATION: "Create Quotation",
  CREATE_INVOICE: "Create Invoice",
  QUOTATION_SUCCESS: " Created Successfully",
  QUOTATION_UPDATE: " Updated Successfully",
  Quotation_UPDATE_FAILURE: " Not Updated",
  Quotation_FAILURE: "Quotation Not Created",
  QUOTE_DELETE_SUCCESS: "Quotation Deleted Successfully",
  QUOTE_DELETE_FAIL: "Quotation Not Deleted",
  CUSTOMER_SUCCESS: "Customer Created Successfully",
  CUSTOMER_EDIT_SUCCESS: "Customer Updated Successfully",
  CUSTOMER_EDIT_FAIL: "Customer Not Updated",
  CUSTOMER_DELETE_SUCCESS: "Customer Deleted Successfully",
  CUSTOMER_DELETE_FAIL: "Customer Not Deleted",
  INVOICE_DELETE_SUCCESS: "Invoice Deleted Successfully",
  INVOICE_DELETE_FAIL: "Invoice Not Deleted",
  Customer_FAILURE: "Customer Not Created",
  Order_SUCCESS: "Order Created Successfully",
  ORDER_FAIL: "Order Not Created",
  ORDER_UPDATE_SUCCESS: "Order Updated Successfully",
  ORDER_UPDATE_FAILED: "Order Not Updated",
  ORDER_DELETE_SUCCESS: "Order Deleted Successfully",
  ORDER_DELETE_FAIL: "Order Not Deleted",
  INVOICE_SUCCESS: "Invoice created Successfully",
  INVOICE_UPDATE: "Invoice Updated Successfully",
  Invoice_UPDATE_FAILURE: "Invoice Not Updated",
  Invoice_FAILURE: "Invoice Not Created",
  VIEW_TXT: "View",
  DELETE_TXT: "Delete",
  QUOTE_VIEW: "View Quotation",
  QUOTE_EDIT: "Edit Quotation",
  BACK_QUOTE:  "Back to All Quotation",
  QUOTE_NUMBER: "Quotation Number",
  CONVERT_TXT: "Convert to Invoice",
  PRINT_TXT: "Print/PDF",
  EDIT_TXT: "Edit",
  UPDATE_TXT: "Update",
  IGST_ERR: "IGST Number must be a valid number",
  IGST_ERROR: "IGST Number must not contain alphabets",
  SGST_ERR: "SGST Number must be a valid number",
  SGST_ERROR: "SGST Number must not contain alphabets",
  GST_ERR: "GST Number must be a valid number",
  GST_ERROR: "GST Number must not contain alphabets",
  AMT_ERROR: "Amount must be a valid number",
  AMT_ERR: "Amount must not contain alphabets",
  hometext: "In the works, coming your way!",
  LOGOUTMODAL :  "Are you sure you want to Log out?",
  LOGOUTSESSION: "Logging out will end your current session",
  LOGOUT: "Logout",
  DELETEMODAL: "Are you sure you want to delete?",
  ACTIONUNDONE: "This action cannot be undone",
  FOOTER_TXT:"All Rights Reserved by ",
  PHEONIX_TXT:"Pheonix solutions",
  TERM_TEXT: "Terms and Conditions |  Privacy  Policy | Security Policy",
  TERMS:"Terms and Conditions",
  LOGIN_TEXT: "Admin Login",
  QUOTE_TERM: "Terms and Conditions:",
  QUOTE_NOTES: "Quotation Notes:",
  CUSTOM_NOTES: "Customer Notes:",
  ACCESS_DENIED: "Your Account is Deactivated",
  SUPPORT:"Please contact customer support for assistance or to reactivate your account.",
  PAYMENT : "Payment",
  QUAN:"Quantity",
  REQUEST:"REQUEST",
  CTAX:'CGST',
  STAX:'SGST',
  ITAX:'IGST',
  DISCOUNT:'Discount',
  PDT_TXT:'Product Plan'
};
export const productsdata = [
  { id: 1, title: "Product 1", imageUrl: productImage },
  { id: 2, title: "Product 2", imageUrl: productImage },
  { id: 3, title: "Product 3", imageUrl: productImage },
  { id: 4, title: "Product 4", imageUrl: productImage },
  { id: 5, title: "Product 5", imageUrl: productImage },
  { id: 6, title: "Product 6", imageUrl: productImage },
  { id: 7, title: "Product 7", imageUrl: productImage },
  { id: 8, title: "Product 8", imageUrl: productImage },
  { id: 9, title: "Product 9", imageUrl: productImage },
];
export const servicesdata = [
  { id: 1, title: "Service 1", imageUrl: productImage },
  { id: 2, title: "Service 2", imageUrl: productImage },
  { id: 3, title: "Service 3", imageUrl: productImage },
  { id: 4, title: "Service 4", imageUrl: productImage },
  { id: 5, title: "Service 5", imageUrl: productImage },
  { id: 6, title: "Service 6", imageUrl: productImage },
  { id: 7, title: "Service 7", imageUrl: productImage },
  { id: 8, title: "Service 8", imageUrl: productImage },
  { id: 9, title: "Service 9", imageUrl: productImage },
];
export const serviceplans = [
  {
    id: 1,
    title: "Plan 1",
    content: [
      "Whether you've used it in a past life,",
      "Have never heard of it, we'll help you",
      "navigate choosing the right product,",
      "Setting it up, and learning the best practices.",
      "So grab our service and let's",
      "go!",
    ],
  },
  {
    id: 2,
    title: "Plan 2",
    content: [
      "Whether you've used it in a past life,",
      "Have never heard of it, we'll help you",
      "navigate choosing the right product,",
      "Setting it up, and learning the best practices.",
      "So grab our service and let's",
      "go!",
    ],
  },
  {
    id: 3,
    title: "Plan 3",
    content: [
      "Whether you've used it in a past life,",
      "Have never heard of it, we'll help you",
      "navigate choosing the right product,",
      "Setting it up, and learning the best practices.",
      "So grab our service and let's",
      "go!",
    ],
  },
];
export const pdfdata = {
  PHEONIX_ADDRESS: `
  Pheonix Solutions,
  1/72A, Pacha Goundan Palayam,
  PeriyaVeeraSangili(PO),
  Vijayamangalam-638056,
  Erode.
  GSTIN: 33BJYPD0540E1ZI`,
};
export const mockData = {
  typographyData: [
    { id: 1, label: "First Name:", value: "jahnavi" },
    { id: 2, label: "Last Name:", value: "kk" },
    { id: 3, label: "Email:", value: "mounika@gmail.com" },
    { id: 4, label: "Password:", value: "admin@123" },
  ],
  contactInfoData: [
    { id: 1, label: "Contact Name:", value: "Mounika" },
    { id: 2, label: "Company Name:", value: "Mounika" },
    { id: 3, label: "Phone Number:", value: "8978507998" },
    { id: 4, label: "Website URL:", value: "www.adhikari.com" },
    { id: 5, label: "Email:", value: "mounika@gmail.com" },
    {
      id: 6,
      label: "Communication Address:",
      value: "12, Periyar Street, Madurai - 625001, Tamil Nadu, India",
    },
  ],
  billingInfoData: [
    { id: 1, label: "GSTIN", value: "123456777" },
    { id: 2, label: "Legal Business Name", value: "kk" },
    {
      id: 3,
      label: "Billing Address",
      value: "12, Periyar Street, Madurai - 625001, Tamil Nadu, India",
    },
  ],
};
